<script>
import RegionBase from './RegionBase'
import { globalManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'global',
      activeIntro: 'Global Giveaways',
      gameList: globalManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - Global',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
